@tailwind base;
@tailwind components;
@tailwind utilities;

/* Reset and global styles should be in the 'base' layer */
@layer base {
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }

  p,
  span,
  li,
  a {
    /* Including additional text elements */
    @apply font-body;
  }


}


/* .Mui-selected {
  color: #005eb8 !important;
} */

.general-physician-label {
  background-color: #8e8e8e;
}

.allergy-label {
  background-color: #74877f;
}

.anesthesiology-label {
  background-color: #5e8d9a;
}

.cardiology-label {
  background-color: #cc4d4d;
}

.dermatology-label {
  background-color: #d17d5d;
}

.endocrinology-label {
  background-color: #b68e40;
}

.gastroenterology-label {
  background-color: #d15f00;
}

.geriatrics-label {
  background-color: #a5a5a5;
}

.hematology-label {
  background-color: #cc4d4d;
}

.neurology-label {
  background-color: #60789b;
}

.nephrology-label {
  background-color: #8a9a8b;
}

.gynecology-label {
  background-color: #c54fc5;
}

.oncology-label {
  background-color: #d18b97;
}

.ophthalmology-label {
  background-color: #5e9a89;
}

.orthopedics-label {
  background-color: #6c8d5b;
}

.pathology-label {
  background-color: #d16d7a;
}

.pediatrics-label {
  background-color: #60789b;
}

.psychiatry-label {
  background-color: #9575cd;
}

.pulmonology-label {
  background-color: #6c8db4;
}

.radiology-label {
  background-color: #7893a8;
}

.rheumatology-label {
  background-color: #d1a270;
}

.urology-label {
  background-color: #9468a6;
}



.country-dropdown .MuiAutocomplete-input {
  padding: 7.5px 4px 7.5px 50px !important;
}


.MuiTabs-indicator {
  background-color: #005eb8 !important;
}